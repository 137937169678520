body {
  height: 100vh;
  margin: 0;
  position: relative;
}

.box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
     -ms-flex-align: center;
        align-items: center;
  -webkit-box-pack: center;
     -ms-flex-pack: center;
   justify-content: center;
}

#root > div > div > div.user-profile > img {
	height: 100px;
    width: 100px;
    border-radius: 10px;
    margin-left: 100px;
    float: top right;
}
.user-profile {
  display:none
}

#root > div > div > div:nth-child(1) > section > div > input[type="text"] {
  text-align:center;
}
#root > div > div > div:nth-child(1) > section > div > textarea {
  width:100%;
  text-align:center
}
#root > div > header > div > a {
  color:white
}

#root > div > div > div:nth-child(2) > section > form
{
  display:none
}
#root > div > header > div > div > div > img {
  height: 35px;
    width: 35px;
    border-radius: 5px;
}
#root > div > header {
  background-color:blue
}
#root > div > header > div > div {
  float:right;
}
.box-content {
  background: transparent;
  color: #fff;
  padding: 20px;
  font-family: sans-serif;
  text-align: center;
  align-content: center;
  align-items: center;
}
.box-content .blu {
color: #34A853;
font-size: 5em;
display:inline;
font-weight:700;
}
.box-content .red {
color: #EA4335;
font-size: 5em;
display:inline;
font-weight:700;
}
.box-content .yelo {
color: #FBBC05;
font-size: 5em;
display:inline;
font-weight:700;
}
.box-content .grin {
color: #4285F4;
font-size: 4em;
display:inline;
text-align:center;
}
.gray {color:gray}
.search-btn { 
background-color:#f2f2f2;
border-color:#f2f2f2;
border-radius: 4px;
font-family: arial,sans-serif;
font-size: 13px;
font-weight: bold;
height: 36px;
line-height: 27px;
border-width:0
}
.search-btn:hover { 
border-width:1px;
box-shadow: 0 2px 2px 2px rgba(0,0,0,0.16),
            0 0 0 1px rgba(0,0,0,0.08);
  z-index: 3;
}
.box-content input[type=text] {
-webkit-transition: all 0.30s ease-in-out;
-moz-transition: all 0.30s ease-in-out;
-ms-transition: all 0.30s ease-in-out;
-o-transition: all 0.30s ease-in-out;
outline: none;
padding: 3px 0px 3px 3px;
margin: 5px 1px 3px 0px;
border: 1px solid #DDDDDD;
width:484px;height:44px;border-radius: 3px
}
.box-content input[type=text]:hover {
box-shadow: 0 3px 3px 3px rgba(0,0,0,0.16),
            0 0 0 1px rgba(0,0,0,0.08);
}
.box-content input[type=text]:focus {
box-shadow: 0 3px 3px 3px rgba(0,0,0,0.16),
            0 0 0 1px rgba(0,0,0,0.08);
}
#coin {
  height: 50px;
  font-size: 20px;
}